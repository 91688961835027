import React, { Component } from "react";

import "../../styles/index.scss";

import Layout from "../../components/layout";
import Goal from "../../components/projects/goal";
import Header from "../../components/projects/header";
import Task from "../../components/projects/task";
import Role from "../../components/projects/role";
import Banner from "../../components/projects/banner";
import Research from "../../components/projects/research";
import Visual from "../../components/projects/visual";
import Learning from "../../components/projects/learning";
import Others from "../../components/projects/others";

import header from "../../images/urbansitter/header.svg";
import bannerImg from "../../images/urbansitter/banner.svg";
import bookingImg from "../../images/urbansitter/booking.png";
import dataImg from "../../images/urbansitter/data.png";
import rescheduleImg from "../../images/urbansitter/reschedule.png";
import acceptImg from "../../images/urbansitter/accept.png";
import testresult from "../../images/urbansitter/testresults.png";
import oldBooking from "../../images/urbansitter/oldbooking.png";
import newBooking from "../../images/urbansitter/newbooking.png";
import noPagination from "../../images/urbansitter/nopagination.png";
import pagination from "../../images/urbansitter/pagination.png";

import urbansitterOne from "../../videos/urbansitter-1.mp4";
import urbansitterTwo from "../../videos/urbansitter-2.mp4";
import urbansitterThree from "../../videos/urbansitter-3.mp4";

class Urbansitter extends Component {
  render() {
    const info = {
      title: "Urbansitter",
      description:
        "Improving the recurring booking process for parents looking for caregivers.",
      date: "October 2021 - Present",
      url: "https://urbansitter.com",
      linkName: "Visit Website",
      image: `${header}`,
      backgroundColor: "#E4F6F9",
      titleColor: "#00BBCD",
    };
    const capacity = {
      collaborators: [
        "David Smith - VP, Product",
        "Laura Crowther - Senior Product Manager",
        "Teddy Del Rosario - Software Engineer, IOS",
        "Travis Dobbs  - VP, Engineering",
      ],
      contributions: [
        "User Research",
        "Information Architecture",
        "Interface Design",
        "Usability Testing",
      ],
      background:
        "UrbanSitter make it simpler for parents to find great caregivers for child care, pet care and senior care  through their friends and social circles. UrbanSitter helps to support families and caregivers so they can say yes to more in their lives, pursuing their choice of career, education, family and self",
      problemDescription:
        "UrbanSitter is historically strong in supporting the one-time care find and booking flows, however we have much room to improve on the recurring side of the marketplace. Since COVID< companies are more motivated to offer care benefits to their employees so that they may find and manage recurring care (part- or full-time). Improving the employee experience of recurring care to increase the odds of securing a successful contract renewal.",
      problemList: [
        "The brand was not reflective of the scale the business was looking to go.",
        "Adding a product to the application was a Herculean task, which dissuaded new users from using the app. New users with multiple products hated adding the products one by one before actively using the application because the product page required too many details to be filled.",
        "Onboarding users was problematic. New users didn’t know the next task to complete after signing up.",
        "There was no comprehensive data analytics to help the users make more informed decisions. Therefore, users had little or no incentive to record their orders.",
        "Order statuses were confusing to understand, as there were too many labels, which made filtering confusing.",
      ],
    };

    const goalOutcome = {
      goal: "Add new verticals to the recurring booking flow and decrease the number of expired recurring bookings to below 30% by improving the employee experience of recurring care to reduce member friction and frustration and reducing sitters frustration with accepting bookings.",
      outcome: "An improved recurring booking experience, allowing caregivers accept/decline recurring booking experience in one go! We tested with caregivers and they found the experience so much better than the current one.",
    };

    const role = {
      description:
        "I collaborated with the Product Manager to conduct user research and usability testing. I also led the  design efforts working in Figma to design the user interface, and built prototypes and tested using Maze for feedback, and collaborated with the developers to ensure it was adequately implemented.",
    };

    const banner = {
      image: `${bannerImg}`,
      alt: "Banner Image",
    };

    return (
      <Layout newStyle="dark" pageTitle="projectitem bumpa">
        <Header
          title={info.title}
          description={info.description}
          date={info.date}
          link={info.link}
          image={info.image}
          backgroundColor={info.backgroundColor}
          linkName={info.linkName}
          url={info.url}
          titleColor={info.titleColor}
        />
        <Task
          collaborators={capacity.collaborators}
          background={capacity.background}
          contributions={capacity.contributions}
          problemDescription={capacity.problemDescription}
          problemList={capacity.problemList}
        />

        <Goal
          goal={goalOutcome.goal}
          outcome={goalOutcome.outcome}
          backgroundColor={info.backgroundColor}
          titleColor={info.titleColor}
        />
        <Role role={role.description} />
        <Banner
          image={banner.image}
          alt={banner.alt}
          backgroundColor={info.backgroundColor}
        />
        <Research>
          <div className="projectitem__research">
            <p>We looked at the current numbers to evaluate </p>
            <ul className="projectitem__research__result">
              <li>
                The flow in its current state adds some complexities to the
                *booking management* process. and this lead to 34% of recurring
                bookings request expire (receive no response from a caregiver).
              </li>
              <li>
                Additionally, other verticals aren’t supported in the recurring
                booking flow.
              </li>
              <li>
                It is also time consuming and annoying for a caregiver to
                respond to each individual booking request when they receive a
                recurring booking. As a result, sitters often won’t respond and
                the bookings will expire.
              </li>
            </ul>

            <div className="projectitem__research__data">
              <h2>
                “34% of recurring bookings request expire (receive no response
                from a caregiver).”
              </h2>
              <img src={dataImg} alt="Research Data" />
            </div>

            <h2>Improved User flow</h2>
            <p>We introduced a new user flow based on what worked</p>
            <div className="projectitem__research__flow">
              <h6>CREATE A BOOKING</h6>
              <img src={bookingImg} alt="Create a booking flow" />
            </div>
            <div className="projectitem__research__flow">
              <h6>PARENT - RESCHEDULE OR CANCEL BOOKING(S)</h6>
              <img src={rescheduleImg} alt="Reschedule booking flow" />
            </div>
            <div className="projectitem__research__flow">
              <h6>SITTER - ACCEPT OR DECLINE BOOKING(S)</h6>
              <img src={acceptImg} alt="Accept Booking flow" />
            </div>
          </div>
        </Research>
        <Visual title="Visual Design">
          <div className="visualdesign__item">
            <h3>PARENT - CREATING A BOOKING</h3>
            <p>
              The new flow introduced a simplified process with the addition of
              the verticals following the IOS guideline and existing design
              system of the application. It first shared with the engineering
              team. Good feedback was gotten. The initial design had one-off
              booking and recurring booking on the same list. Teddy and Travis
              explained that with the current engineering architecture, it will
              be hard to mix both types of bookings all at once, and it was
              easier to separate them into groups.
            </p>
            <div className="single-video urbansitter">
              <video autoPlay loop>
                <source src={urbansitterOne} type="video/mp4" />
              </video>
            </div>
          </div>
          <div className="visualdesign__item">
            <h3>PARENT - SCHEDULING AND CANCELING BOOKING REQUEST(S)</h3>
            <p>
              One of the new features needed to be introduced was allowing
              parents perform bulk actions on recurring bookings created.
              Parents want to be able to bulk reschedule or cancel days within a
              recurring booking set.
            </p>
            <div className="single-video urbansitter">
              <video autoPlay loop>
                <source src={urbansitterTwo} type="video/mp4" />
              </video>
            </div>
          </div>
          <div className="visualdesign__item">
            <h3>SITTERS - ACCEPT/DECLINE BOOKING(S)</h3>
            <p>
              Sitters can book accept or decline bookings and can also cancel
              already accepted bookings and state their reason for canceling a
              booking.
            </p>
            <div className="single-video urbansitter">
              <video autoPlay loop>
                <source src={urbansitterThree} type="video/mp4" />
              </video>
            </div>
          </div>
          <div className="projectitem__testing">
            <h2>Usability Testing</h2>
            <p>
              I tested using [maze.co](http://maze.co) to gather user feedback
              and discover pain points in the current design. With the help of
              the product manager, Laura, we recruited participants for both the
              parent and sitter flow. Overall, we received good feedback from
              the participants.
            </p>
            <img src={testresult} alt="Usability Testing Result" />

            <p>Some of the pain points discovered were:</p>
            <ul className="projectitem__research__result">
              <li>
                Increasing the size of the one-time/recurring booking toggle so
                that it’s easier to find
              </li>
              <li>
                Accept All/Decline All actions felt weird because no checkboxes
                were selected (it might be too easy to take that action without
                realizing it)
              </li>
              <li>Breaking up large recurring booking sets into pagination.</li>
            </ul>
            <div className="bk bk-grid urbansitter">
              <div class="image-column">
                <h6>Before</h6>
                <img src={oldBooking} alt="Old Booking Screen" />
              </div>
              <div className="image-column">
                <h6>After</h6>
                <img src={newBooking} alt="New Booking Screen" />
              </div>
            </div>
            <div className="bk bk-grid urbansitter">
              <div class="image-column">
                <h6>Before</h6>
                <img src={noPagination} alt="Old Booking Screen" />
              </div>
              <div className="image-column">
                <h6>After</h6>
                <img src={pagination} alt="New Booking Screen" />
              </div>
            </div>
          </div>
        </Visual>
        <Others
          backgroundColor={info.backgroundColor}
          fillColor={info.titleColor}
          previous="/projects/bumpa"
          next="/projects/indicina"
        />
      </Layout>
    );
  }
}

export default Urbansitter;
